import Vue from 'vue'

import config from '../../config'
import GlobalService from '../../services/global';

import ItineraryService from '../../services/itinerary';

//custom components
import CstmCountryPicker from '../../components/cstm-country-picker/cstm-country-picker.vue';
import CstmLocationPicker from '../../components/cstm-location-picker/cstm-location-picker.vue';
import Loading from '../../components/loading/loading.vue';

export default {
  name: 'NewItineraryInfo',

  components: {
    CstmCountryPicker,
    CstmLocationPicker,
    Loading
  },

  computed: {
    modalPhotos() {
      return this.itinerary.photos.filter(x => x);
    }
  },

  methods: {
    form1() {
      if (!this.$refs.form1.validate()) return;
      if (!this.itinerary.location.placeId) return this.valid1 = false;
      this.proceed(2);
    },

    form2() {
      if (!this.$refs.form2.validate()) return;
      this.proceed(3);
    },

    form3() {
      if (!this.$refs.form3.validate()) return;
      this.proceed(4);
    },

    form4() {
      if (!this.$refs.form4.validate()) return;
      this.proceed(5);
    },

    form5() {
      if (!this.$refs.form5.validate()) return;
      this.proceed(6);
    },

    form6() {
      if (!this.$refs.form6.validate()) return;
      this.proceed(7);
    },

    getPhotoURL(data) {
      return GlobalService.getItPhoto(data);
    },

    getRef(index) {
      return this.$refs['inputUpload'+index][0];
    },

    handleClick(index, photo) {
      if (!photo.id) this.pickImage(index);
      else photo.sheet = !photo.sheet;
    },

    setAsDp(index, photo) {
      photo.sheet = false;
      this.photos.splice(index, 1);
      this.photos.splice(0, 0, photo);

      this.itinerary.photos.splice(index, 1);
      this.itinerary.photos.splice(0, 0, photo.id);
      this.proceed();
    },

    removeImage(index, photo) {
      this.photos.splice(index, 1);
      this.photos.push(this.photoItem);

      //and actual
      this.itinerary.photos.splice(index, 1);

      photo.sheet = false;
      this.proceed();
    },

    pickImage(index) {
      this.getRef(index).click();
    },

    pushPhotoObject(index, id, uploading, uploadProgress, sheet) {
      Vue.set(this.photos, index, {uploading: uploading, id: id, uploadProgress: uploadProgress, sheet: sheet});
    },

    invalidImageError() {
      this.showError = true;
      setTimeout(() => { this.showError = false }, 2000);
    },

    isFileImage(file) {
      return file && file['type'].split('/')[0] === 'image';
    },

    fileSelected(index) {
      const file = this.getRef(index).files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();
      const localURL = URL.createObjectURL(file);
      this.pushPhotoObject(index, localURL, true, 0, false);
      this.upload(file, index, localURL);
    },

    upload(file, index, localURL) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      Vue.axios.defaults.headers.common['X-Request-Pattern'] = 'new'; //for backend ais
      
      return Vue.axios
        .post(`file/i`, formData, {
          onUploadProgress: progressEvent => {
            const uploadProgress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
            this.pushPhotoObject(index, localURL, true, uploadProgress, false);
          }
        })
        .then(res => {
          //removing the added header
          Vue.axios.defaults.headers.common['X-Request-Pattern'] = undefined;
          const uploadedPhoto = res.data._id;
          this.pushPhotoObject(index, uploadedPhoto, false, 0, false);
          const photos = this.photos.map(x => x.id);
          this.itinerary.photos = photos;
          this.proceed();
        })
        .catch(err => {
          console.log(err);
        })
    },

    proceed(nstep = 0) {
      let itObject = this.itinerary;

      this.saving = true;
      if (itObject._id) {
        return ItineraryService
          .updateItinerary(itObject)
          .then(r => {
            this.itinerary = r.data;
            if (nstep) this.e6 = nstep;
            this.saving = false;
          })
          .catch(err => {
            console.log(err);
            this.saving = false;
          })
      }
      //create new
      return ItineraryService
        .createItinerary(itObject)
        .then(r => {
          this.itinerary = r.data;
          if (nstep) this.e6 = nstep;
          this.saving = false;
        })
        .catch(err => {
          console.log(err);
          this.saving = false;
        })  
    },

    submit() {
      if (!this.itinerary.photos.filter(x => x).length) return;
      this.saving = true;
      return ItineraryService
        .changeStatus(this.itinerary._id, 'active')
        .then(() => {
          this.saving = false;
          window.scrollTo(0,0);
          this.submittedModal = true;
        })
        .catch(e => {
          this.saving = false;
          console.log(e);
        });
    },

    fetchItinerary() {
      this.showLoadingModal = true;
      return ItineraryService
        .getItinerary(this.itineraryId)
        .then(response => {
          let itinerary = response.data;
          itinerary.uname = 'l';
          this.itinerary = ItineraryService.mapItinerary(itinerary);
        })
        .catch(error => {
          console.log(error);
          this.showLoadingModal = false;
        }); 
    },

    addMoreToMorning() {
      this.itinerary.morningItinerary.push({
        type: 'Place',
        title: '',
        time: '',
        description: '',
        location: {
          placeId: '',
          description: ''
        }
      });
    },

    removeMorningItem(item) {
      const index = this.itinerary.morningItinerary.indexOf(item);
      if (index > -1) this.itinerary.morningItinerary.splice(index, 1);
    },

    addMoreToAfternoon() {
      this.itinerary.afternoonItinerary.push({
        type: 'Place',
        title: '',
        time: '',
        description: '',
        location: {
          placeId: '',
          description: ''
        }
      });
    },

    removeAfternoonItem(item) {
      const index = this.itinerary.afternoonItinerary.indexOf(item);
      if (index > -1) this.itinerary.afternoonItinerary.splice(index, 1);
    },

    addMoreToEvening() {
      this.itinerary.eveningItinerary.push({
        type: 'Place',
        title: '',
        time: '',
        description: '',
        location: {
          placeId: '',
          description: ''
        }
      });
    },

    removeEveningItem(item) {
      const index = this.itinerary.eveningItinerary.indexOf(item);
      if (index > -1) this.itinerary.eveningItinerary.splice(index, 1);
    },

    addMoreToNight() {
      this.itinerary.nightItinerary.push({
        type: 'Place',
        title: '',
        time: '',
        description: '',
        location: {
          placeId: '',
          description: ''
        }
      });
    },

    removeNightItem(item) {
      const index = this.itinerary.nightItinerary.indexOf(item);
      if (index > -1) this.itinerary.nightItinerary.splice(index, 1);
    }

  },

  mounted() {
    this.loading = true;
    
    let promises = [];

    promises.push(
      /*ExperienceService
        .getEnums()
        .then(result => {
          const newCats = [];
          result.data.categories.forEach(item => {
            newCats.push({selected: false, name: item});
          })
          this.categories = newCats;
          this.duration = result.data.duration;
          this.channel = result.data.channel;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        })*/
    )
    if (this.itineraryId) promises.push(this.fetchItinerary());

    return Promise.all(promises)
      .then(() => {
        if (this.itineraryId && this.$route.params.slug === 'modify') {

          //map photos
          this.itinerary.photos.forEach(item => {
            if (item) this.photos.push({uploading: false, id: item, uploadProgress: 0, sheet: false})
            else this.photos.push(this.photoItem);
          })

          //map country/currency
          const c = this.itinerary.pricing.currency;
          if (c && c.name) this.country = c;

          //hide the modal
          this.showLoadingModal = false;
        } 
        
        const totalEmpty = 8 - this.photos.length;
        for (let p = 0; p < totalEmpty; p++) this.photos.push(this.photoItem);
      });
  },

  watch: {
    country(val) {
      if (val) {
        this.itinerary.pricing.currency = {
          name: val.name,
          symbol: val.symbol,
          code: val.code,
          country: val.country
        }
      }
    }
  },

  data() {
    return {
      loading: false, 
      submittedModal: false,

      showLoadingModal: false,
      itineraryId: this.$route.params.id,

      e6: 1,

      valid1: false,
      valid2: false,
      valid3: false,

      le: false,

      timeModal: false,

      placeholder: config.meta.placeholder,
      saving: false,
      categories: [],
      duration: [],
      channel: [],
      photos: [],
      lazy: require("@/assets/imgs/placeholder.png"),
      yesnoItems: [ {key: 'Yes', value: true}, {key: 'No', value: false}],
      country: {
        code: '',
        symbol: '',
        country: '',
        name: ''
      },

      itinerary: {
        title: '',
        location: {
          placeId: '',
          description: ''
        },
        morningItinerary: [{
          type: 'Place',
          title: '',
          time: '',
          description: '',
          location: {
            placeId: '',
            description: ''
          }
        }],
        afternoonItinerary: [{
          type: 'Activity',
          title: '',
          time: '',
          description: '',
          location: {
            placeId: '',
            description: ''
          }
        }],
        eveningItinerary: [{
          type: 'Event',
          title: '',
          time: '',
          description: '',
          location: {
            placeId: '',
            description: ''
          }
        }],
        nightItinerary: [{
          type: 'Food',
          title: '',
          time: '',
          description: '',
          location: {
            placeId: '',
            description: ''
          }
        }],
        pricing: {
          isAvailableFree: true,
          amount: 0,
            currency: {
              code: '',
              symbol: '',
              country: '',
              name: ''
            }
        },
        leftAt: 'basic',
        photos: []
      },

      photoItem: {
        uploading: false, 
        id: undefined, 
        progress: 0, 
        sheet: false
      },

      validationRules: {
        basicRules: [
          v => !!v || 'It cannot be empty'
        ],
        priceRules: [
          v => /^\d+$/.test(v) || this.itinerary.pricing.isAvailableFree || 'Must be valid a valid price',
          v => !!v || this.itinerary.pricing.isAvailableFree || 'Must be valid a valid price',
          v => v != 0 || v != '0' || this.itinerary.pricing.isAvailableFree || 'Must be valid a valid price'
        ]
      }
    }
  }
}